import React from "react"
import { graphql } from "gatsby"
import Layout from "../layouts"
import Head from "../components/head"
// import HomeHero from '../components/home-hero';
import Articles from '../components/articles';
import QuoteSlider from '../components/quote-slider';
import Solutions from '../components/solutions';
import CTAImage from '../components/cta-image';
import TestimonialSlider from '../components/testimonial-slider';
import CopyRightBlock from "../components/copyright"
import HomeHero from "../components/home-hero-v2"
import TextImage from "../components/text-image"

import AnniversaryLogo from '../images/90-anniversary-logo.png';
import '../css/anniversary.css';


import YoastSeoParts from '../fragments/seo';

const FrontPage = props => {
  const { title, excerpt, wpPage, latestPosts } = props.data;
  const { seo } = wpPage
  const {slides} = wpPage.homeSlides;
  const allSolutions = props.data.allWpSolution.nodes;

  const section = "front";

  // console.log(wpPage.homeSlides.contentBlock);
  return (
    <Layout>
      <Head title={seo.title} description={seo.metaDesc} meta={seo} />
      <HomeHero slides={slides}></HomeHero>
      {/* <HomeHero data={wpPage.HeroHome} image={wpPage.featuredImage} /> */}
      <div className="home-content">
        <div className="anniversary">
          <div className="flexbox">
            <div className="anniversary-logo">
              <img src={AnniversaryLogo}></img>
            </div>
            <div className="anniversary-text">
              <h2>A Legacy of Leadership.</h2>
              <h2>A Solution-Driven Future.</h2>
            </div>
          </div>
            <div className="anniversary-copy">
              <h3>IT IS THE PASSION &amp; TALENT OF OUR PEOPLE THAT PAVE THE WAY</h3>
              <p>For 90 years, Roanoke has proudly supported companies in supply-chain, logistics, and global trade by providing expert risk management
              and compliance solutions they can rely on. Our success comes from our people's passion and talent, along with our commitment to building
              strong, lasting partnerships.</p>
              <p>Founded in 1935 as the first provider of U.S. customs bonds and later appointed as the first ATA Carnet provider in the U.S., Roanoke has
              been a trusted resource for logistics companies and their clients. This enduring collaboration and dedication are what we call “the Roanoke
              way.”</p>   
              <p>As we celebrate this incredible milestone, we reflect on our legacy with pride and look forward to a bright future. Thank you to our
              exceptional team, valued partners, and loyal clients who have been part of this journey.</p> 
            </div>
        </div>
      <Solutions title="Our Global<br>Logistic Solutions" solutions={allSolutions} hideCTA={true} />

      <TextImage content={wpPage.homeSlides.contentBlock} section={section}></TextImage>

     {/* { !wpPage.copyRight.hideLeftImageRightCopy2 &&
            <CopyRightBlock image={wpPage.copyRight.imageLeft2Image} title={wpPage.copyRight.imageLeft2Title} copy={wpPage.copyRight.imageLeft2Copy} cta={wpPage.copyRight.imageLeft2Cta} />
          } */}

      <QuoteSlider testimonials={wpPage.TestimonalList.testimonials} section={section} />
      {!wpPage.testimonials.hideTestimonialSlider &&
        <TestimonialSlider testimonials={wpPage.testimonials.testimonialSlider} />
      }
      {/* <CTAImage leftColumn={wpPage.CTAImage.leftColumn} rightColumn={wpPage.CTAImage.rightColumn} /> */}
      <Articles posts={latestPosts.nodes} section={section} />
      </div>
    </Layout>
  )
}
export default FrontPage

export const query = graphql`
  {
    wpPage(isFrontPage: {eq: true}) {
      title
      link
      seo {
        ...seoParts
      }
      homeSlides {
        contentBlock{
          imagePosition
          image {
            altText
            caption
            localFile {
              publicURL
            }
          }
          superHeadline
          headline
          content
          ctaButtons {
            buttonLabel
            buttonType
            link {
              url
              target
            }
          }
        }
        slides{
          backgroundImage {
            altText
            localFile {
              publicURL
            }
          }
          frontImage {
            altText
            localFile {
              publicURL
            }
          }
          headline
          buttons {
            buttonLabel
            buttonType
            link {
              url
              target
            }
          }
          subheads{
            subhead
          }
        }
      }



      featuredImage {
        node {
          localFile {
            publicURL
          }
        }
      }

      TestimonalList : homepageFields {
          testimonials {
          ... on WpTestimonial {
            id
            content
            cptSingleTestimonial {
              firstName
              lastName
              position
              testimonial
              signature {
                localFile {
                  publicURL
                }
              }
            }
            featuredImage {
              node {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
      solutionList: homepageFields {
        solutions {
          ... on WpSolution {
            id
            uri
            title
            link
            cptSolution {
              invertColor
              mainColor
              shortDescription
              hoverCopy
              icon {
                altText
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
      copyRight : homepageFields{
            hideLeftImageRightCopy2
            imageLeft2Title
            imageLeft2Copy
            imageLeft2Image {
              altText
              localFile {
                publicURL
              }
            }
            imageLeft2Cta {
              target
              url
              title
            }
          }
      
      CTAImage : homepageFields {
        leftColumn {
          copy
          cta {
            target
            title
            url
          }
          image {
            altText
            localFile {
               publicURL
            }
          }
        }
        rightColumn {
          cta {
            target
            title
            url
          }
          copy
          image {
            altText
            localFile {
              publicURL
            }
          }
        }
      }

      testimonials: homepageFields {
        hideTestimonialSlider
        testimonialSlider {
          ... on WpTestimonial {
            id
            title
            cptSingleTestimonial {
              firstName
              lastName
              position
              testimonial
            }
          }
        }
      }
    }
    allWpSolution(filter: {parentId: {eq: null}}, sort: {fields: title, order: ASC}) {
      nodes {
        id
        title
        link
        cptSolution {
          invertColor
          mainColor
          shortDescription
          hoverCopy
          icon {
            altText
            localFile {
              publicURL
            }
          }
        }
      }
    }
    latestPosts: allWpPost(limit: 8, sort: {fields: date, order: DESC}) {
      nodes {
        id
        link
        title
        excerpt
        featuredImage {
          node {
            localFile {
              publicURL
            }
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
      }
    }
  }

`
